import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore, storage, auth } from './firebase-config';
import { collection, addDoc, doc, updateDoc, getDoc, deleteDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { onAuthStateChanged } from 'firebase/auth';
import { deleteObject } from 'firebase/storage';

function UploadCar() {
    const [carId, setCarId] = useState(null);
    const [cars, setCars] = useState([]);
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [details, setDetails] = useState('');
    const [images, setImages] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchCars = async () => {
            const querySnapshot = await getDocs(collection(firestore, 'cars'));
            setCars(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        };
        fetchCars();
    }, []);
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (!user) {
              // No user is signed in, redirect to login
              navigate('/login');
          }
      });

      // Cleanup the subscription
      return () => unsubscribe();
  }, [navigate]);
    useEffect(() => {
        if (carId) {
            setIsEditing(true);
            const fetchCarData = async () => {
                const carDoc = await getDoc(doc(firestore, 'cars', carId));
                if (carDoc.exists()) {
                    const carData = carDoc.data();
                    setName(carData.name);
                    setPrice(carData.price);
                    setDetails(carData.details);
                }
            };
            fetchCarData();
        } else {
            resetForm();
        }
    }, [carId]);

    useEffect(() => {
        setFormValid(name !== '' && price !== '' && details !== '' && images.length > 0);
    }, [name, price, details, images]);

    const resetForm = () => {
        setIsEditing(false);
        setName('');
        setPrice('');
        setDetails('');
        setImages([]);
        setCarId(null);
        setLoading(false);
    };

    const handleImageChange = (e) => {
        setImages([...e.target.files]);
    };

    const uploadImages = async () => {
        const urls = [];
        for (let image of images) {
            const imageRef = ref(storage, `cars/${image.name}`);
            const snapshot = await uploadBytes(imageRef, image);
            const url = await getDownloadURL(snapshot.ref);
            urls.push(url);
        }
        return urls;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formValid) {
            alert("Please fill in all fields and add at least one image.");
            return;
        }
        setLoading(true);

        try {
            let imageUrls = images.length > 0 ? await uploadImages() : [];
            const carData = {
                name,
                price,
                details,
                images: imageUrls,
                status: 'available'
            };

            if (isEditing) {
                const docRef = doc(firestore, 'cars', carId);
                await updateDoc(docRef, carData);
                alert('Car listing updated successfully');
            } else {
                await addDoc(collection(firestore, 'cars'), carData);
                alert('Car uploaded successfully');
            }
            resetForm();
        } catch (error) {
            console.error('Error in operation: ', error);
            alert('Error in operation: ' + error.message);
            setLoading(false);
        }
    };

    const deleteCar = async () => {
      if (carId) {
          try {
              // Find the car data in Firestore to get the image URLs
              const carDocRef = doc(firestore, 'cars', carId);
              const carDoc = await getDoc(carDocRef);
              
              if (carDoc.exists()) {
                  const carData = carDoc.data();
  
                  // Delete images from Firebase Storage
                  if (carData.images && carData.images.length) {
                      const deletePromises = carData.images.map((imageUrl) => {
                          const imageRef = ref(storage, imageUrl);
                          return deleteObject(imageRef);
                      });
  
                      await Promise.all(deletePromises);
                  }
  
                  // Delete car data from Firestore
                  await deleteDoc(carDocRef);
  
                  alert('Car and associated images deleted successfully');
              } else {
                  alert('Car not found');
              }
          } catch (error) {
              console.error('Error deleting car: ', error);
              alert('Error in operation: ' + error.message);
          } finally {
              resetForm();
          }
      }
  };

    const markCarAsSold = async () => {
        if (carId) {
            await updateDoc(doc(firestore, 'cars', carId), {
                status: 'sold',
                price: 'Sold'
            });
            alert('Car marked as sold');
            resetForm();
        }
    };

    return (
        <div className="container mx-auto mt-10">
            <form onSubmit={handleSubmit} className="mb-6">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="car-name">
                        Car Name
                    </label>
                    <input 
                        type="text" 
                        id="car-name"
                        placeholder="Enter Car Name" 
                        value={name} 
                        onChange={(e) => setName(e.target.value)} 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-4 relative">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="car-price">
                        Price
                    </label>
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input 
                        type="text" 
                        id="car-price"
                        placeholder="0.00" 
                        value={price} 
                        onChange={(e) => setPrice(e.target.value)} 
                        className="shadow appearance-none border rounded w-full py-2 pl-7 pr-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="car-details">
                        Details
                    </label>
                    <textarea 
                        id="car-details"
                        placeholder="Enter Details" 
                        value={details} 
                        onChange={(e) => setDetails(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    ></textarea>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Upload Images
                    </label>
                    <input 
                        type="file" 
                        multiple 
                        onChange={handleImageChange} 
                        className="block w-full text-sm text-gray-500
                          file:mr-4 file:py-2 file:px-4
                          file:rounded-full file:border-0
                          file:text-sm file:font-semibold
                          file:bg-violet-50 file:text-violet-700
                          hover:file:bg-violet-100"
                    />
                </div>
                <button 
                    type="submit" 
                    disabled={loading || !formValid} 
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    {loading ? 'Uploading...' : (isEditing ? 'Update Car' : 'Upload Car')}
                </button>
            </form>
            {isEditing && (
                <>
                    <button 
                        onClick={deleteCar} 
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-2"
                    >
                        Delete Car
                    </button>
                    <button 
                        onClick={markCarAsSold} 
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
                    >
                        Mark as Sold
                    </button>
                </>
            )}
            <div>
                <h2 className="text-xl font-bold mt-6 mb-4">Existing Cars</h2>
                {cars.map(car => (
                    <div key={car.id} className="mb-4">
                        <div>{car.name}</div>
                        <button
                            onClick={() => setCarId(car.id)}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2"
                        >
                            Edit
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default UploadCar;
