import React from 'react';

const HeroSection = () => {
  return (
    <div className="bg-teal-800 h-20 flex justify-center items-center">
      <h1 className="text-5xl font-bold text-white">Jordan Auto Sales</h1>
    </div>
  );
};

export default HeroSection;
