const PaymentPlans = () => {
    return (
      <div className="bg-cyan-900 py-8">
        <div className="container mx-auto text-center">
          <h2 className="bg-grey-700 text-white text-3xl font-bold mb-4">Flexible Payment Plan Options</h2>
          <p className="text-xl mb-4 text-white">At Jordan Auto Sales, we offer a variety of payment plans to fit your budget. Whether you prefer low monthly payments or a short-term plan, we have options for you.</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-all">
              <h3 className="text-2xl font-bold">Low Monthly Payments</h3>
              <p className="text-lg text-gray-600 mt-2">Spread the cost over several months with our affordable monthly plans.</p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-all">
              <h3 className="text-2xl font-bold">0% Interest Options</h3>
              <p className="text-lg text-gray-600 mt-2">Enjoy 0% interest on selected vehicles and payment plans.</p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-all">
              <h3 className="text-2xl font-bold">Customizable Plans</h3>
              <p className="text-lg text-gray-600 mt-2">Tailor your payment plan to suit your needs and budget.</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default PaymentPlans;
  