import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, provider } from './firebase-config'; // Adjust the import path
import { signInWithPopup } from 'firebase/auth';

function Login() {
  const navigate = useNavigate();

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then(() => {
        navigate('/upload-car'); // Navigate to Upload Car page on successful login
      })
      .catch((error) => {
        console.error("Error signing in: ", error);
      });
  };

  return (
    <div className="container mx-auto mt-10">
      <button onClick={signInWithGoogle} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Sign in with Google
      </button>
    </div>
  );
}

export default Login;
