import React from 'react';
import HeroSection from './HeroSection';
import FeaturedCars from './FeaturedCars';
import Testimonials from './Testimonials';
import PaymentPlans from './PaymentPlans';
import model from './Images/model.jpg';



const Home = () => {
  return (
    <div className="bg-white">
      <HeroSection />

      {/* Welcome Section */}
      <div className="container mx-auto p-8 flex flex-wrap bg-white rounded-md shadow-md">
  <div className="w-full md:w-1/2 p-4">
    <img src={model} alt="Car" className="w-full  object-cover rounded-md" /> {/* Use the imported image here */}
  </div>
  <div className="w-full md:w-1/2 p-4 flex flex-col justify-center">
    <h2 className="text-3xl font-bold mb-2">Welcome to Jordan Auto Sales</h2>
    <p className="text-lg mb-4">Your trusted car dealership offering quality vehicles at unbeatable prices. Explore our wide range of cars and find the perfect ride for you.</p>
    <address className="text-lg">
      Address: 2332 E 45th St, Indianapolis, IN 46205<br />
      Phone: (317) 652-7707
    </address>
  </div>
</div>


      {/* Featured Cars Section */}
      <div className="bg-black text-white py-8">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-4">Featured Cars</h2>
          <p className="text-xl mb-8">Discover our handpicked selection of top-quality vehicles.</p>
          <FeaturedCars />
        </div>
      </div>

      {/* Testimonials Section */}
      
      <div className="bg-cyan-900  p-8">
        <h2 className="text-3xl font-bold text-center mb-4 text-white">What Our Customers Say</h2>
        <Testimonials />
      </div>

      {/* Payment Plan Options */}
      <PaymentPlans />

      {/* Call to Action Section */}
      <div className="bg-teal-800 text-white py-8 text-center">
        <h2 className="text-4xl font-bold mb-4">Ready to Find Your Next Car?</h2>
        <p className="text-xl mb-8">Visit us today or browse our online inventory to find the perfect car for your needs.</p>
        <a href="/cars" className="bg-white text-blue-700 p-4 rounded-md text-lg font-semibold hover:bg-gray-200 transition-all">Browse Cars</a>
      </div>


      <div className="bg-gray-100">
  {/* Other content */}
  
</div>
    </div>
    
  );
};

export default Home;
