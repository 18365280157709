import React from 'react';
import TeamMember from './TeamMember';

import damen from './Images/damen.png';
const Team = () => {
  // Example team data
  const teamMembers = [
    { image: damen, name: 'Mohammed Al-Damen', role: 'Owner GM' },
    { image: damen, name: 'Damen Al-Damen', role: 'Sales Manager' },
    { image: damen, name: 'Qasim', role: 'Sales Associate' },
    // Add more team member objects here
  ];

  return (
    <div className="bg-gray-800">
      <div className="container mx-auto p-10">
        <h1 className=" text-5xl font-bold mb-4 text-center text-white">Meet the Team</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {teamMembers.map((member, index) => (
            <TeamMember key={index} image={member.image} name={member.name} role={member.role} />
          ))}
        </div>
      </div>
      
    </div>
  );
};

export default Team;
