import React from 'react';

const TeamMember = ({ image, name, role }) => {
  return (
    <div className="p-4 bg-white rounded-md shadow-md hover:shadow-lg transition-all">
      <img src={image} alt={name} className=" object-cover rounded-md" />
      <h2 className="text-2xl mt-4">{name}</h2>
      <p className="text-lg">{role}</p>
    </div>
  );
};

export default TeamMember;
