import React from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {
  return (
    <nav className="bg-gray-900 p-2 text-white flex justify-around shadow-md">
      <Link to="/">Home</Link>
      <Link to="/Cars">Cars for Sale</Link>
      <Link to="/Team">Meet the Team</Link>
      <Link to="/contact">Contact</Link>
    </nav>
  );
};

export default NavBar;
