import React from 'react';
import { SocialIcon } from 'react-social-icons';
const Footer = () => {
    return (
      <footer className="bg-gray-900 text-white py-7">
        <div className="container mx-auto flex flex-wrap justify-between px-4">
          <div className="w-full sm:w-1/3 p-4">
            <h3 className="text-2xl font-bold mb-2">Jordan Auto Sales</h3>
            <address className="text-lg">
              2332 E 45th St, Indianapolis, IN 46205<br />
              Phone: (317) 652-7707
            </address>
          </div>
          <div className="w-full sm:w-1/3 p-4">
            <h3 className="text-2xl font-bold mb-2">Quick Links</h3>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/cars">Cars</a></li>
              <li><a href="/team">Team</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>
          <div className="w-full sm:w-1/3 p-4">
                    <h3 className="text-2xl font-bold mb-2">Follow Us</h3>
                    <SocialIcon url="https://www.instagram.com/jordanautosale/" target="_blank" rel="noopener noreferrer" />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
  