import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home.jsx';
import CarsForSale from './Cars.jsx'; // Assuming this is your updated Cars component
import Team from './Team.jsx';
import Contact from './Contact.jsx';
import Login from './Login';
import UploadCar from './UploadCar';
import NavBar from './NavBar';
import Footer from './Footer'; // Import the Footer component
import './tailwind.css';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <NavBar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cars" element={<CarsForSale />} />
            <Route path="/team" element={<Team />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} /> {/* Keep the login route */}
            <Route path="/upload-car" element={<UploadCar />} /> {/* Keep the upload car route */}
            {/* Add any other routes you might have */}
          </Routes>
        </main>
        <Footer /> {/* Footer stays outside Routes but inside the flex container */}
      </div>
    </Router>
  );
}

export default App;
