// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCZfUNX0-K_-8qDvHxyt_SCffXR1QArwDU",
    authDomain: "jordanauto-7f53c.firebaseapp.com",
    projectId: "jordanauto-7f53c",
    storageBucket: "jordanauto-7f53c.appspot.com",
    messagingSenderId: "588428055741",
    appId: "1:588428055741:web:8adb0ccab1b68fb7cf53d4",
    measurementId: "G-92DNFQPV73"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app);

export { firestore, auth, provider, storage };