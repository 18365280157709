import React, { useState, useEffect } from 'react';
import { firestore } from './firebase-config'; // Adjust the path as needed
import { collection, getDocs } from 'firebase/firestore';

function CarsForSale() {
  const [carsData, setCarsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'cars'));
      const carsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), imageIndex: 0 }));
      setCarsData(carsArray);
    };

    fetchData();
  }, []);

  const handleImageChange = (carId, direction) => {
    setCarsData(carsData.map(car => {
      if (car.id === carId) {
        let newImageIndex = car.imageIndex + direction;
        if (newImageIndex >= car.images.length) {
          newImageIndex = 0;
        } else if (newImageIndex < 0) {
          newImageIndex = car.images.length - 1;
        }
        return { ...car, imageIndex: newImageIndex };
      }
      return car;
    }));
  };

  return (
    <div className="container mx-auto mt-10">
      <h2 className="text-3xl font-bold mb-6 text-center">Cars for Sale</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {carsData.map(car => (
          <div key={car.id} className="border rounded overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
            {car.images && (
              <div className="relative h-64 overflow-hidden">
                <img src={car.images[car.imageIndex]} alt={`Car ${car.id}`} className="w-full h-full object-contain" />
                <button 
                  onClick={() => handleImageChange(car.id, -1)} 
                  className="absolute top-1/2 left-3 transform -translate-y-1/2 text-white bg-black bg-opacity-30 hover:bg-opacity-60 rounded-full p-1">
                  &#9664; {/* Left arrow */}
                </button>
                <button 
                  onClick={() => handleImageChange(car.id, 1)} 
                  className="absolute top-1/2 right-3 transform -translate-y-1/2 text-white bg-black bg-opacity-30 hover:bg-opacity-60 rounded-full p-1">
                  &#9654; {/* Right arrow */}
                </button>
              </div>
            )}
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">{car.name}</div>
              <p className="text-gray-700 text-base">Price: <span className="font-semibold">${car.price}</span></p>
              <p className="text-gray-600">{car.details}</p>
            </div>
          </div>
        ))}
      </div>
      
    </div>
  );
}

export default CarsForSale;
