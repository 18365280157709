import React, { useState } from 'react';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const targetEmail = 'daldamen900@gmail.com'; // Replace with your email
    const subject = encodeURIComponent('Inquiring about Car');
    const body = encodeURIComponent(`Name: ${name}\nEmail: ${email}\nMessage: ${message}`);

    const mailtoLink = `mailto:${targetEmail}?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };

  const isFormValid = name && message; // Checks if both name and message are filled out

  return (
    <div className="bg-teal-800">
      <div className="container mx-auto p-8">
        <h1 className="text-5xl font-bold mb-4 text-center text-white">Contact Us</h1>
        <div className="bg-white p-6 rounded-md shadow-md">
          <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-lg mb-2">Name:</label>
              <input 
                type="text" 
                className="w-full p-2 rounded-md border border-gray-300"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-lg mb-2">Email:</label>
              <input 
                type="email" 
                className="w-full p-2 rounded-md border border-gray-300"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-span-2">
              <label className="block text-lg mb-2">Message:</label>
              <textarea 
                className="w-full p-2 h-32 rounded-md border border-gray-300"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="col-span-2 text-center">
              <button 
                type="submit" 
                className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition-all"
                disabled={!isFormValid} // Button is disabled if form is not valid
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
