const Testimonials = () => {
    
    const testimonials = [
      { quote: 'Amazing service and great quality cars!', name: 'John Doe' },
      { quote: 'I found the perfect car at a great price!', name: 'Jane Smith' },
      { quote: 'Highly recommended for anyone looking for a reliable car.', name: 'Alice Johnson' },
    ];
  
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {testimonials.map((testimonial) => (
          <div className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-all">
            <p className="text-lg italic">{testimonial.quote}</p>
            <p className="text-gray-800 mt-2">- {testimonial.name}</p>
          </div>
        ))}
      </div>
    );
  };
  
  export default Testimonials;
  