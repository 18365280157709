import React from 'react';
import car1 from './Images/car2.jpg';
import car from './Images/car.jpg';
import car3 from './Images/car3.jpg';
const FeaturedCars = () => {
  // Use imported images
  const cars = [
    { image: car1, model: 'SOLD', price: '$SOLD' },
    { image: car, model: 'SOLD', price: '$SOLD' },
    { image: car3, model: 'SOLD', price: '$SOLD' },
   
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {cars.map((car) => (
        <div className="p-4 bg-white text-black font-bold rounded-lg shadow-md hover:shadow-lg transition-all">
          <img src={car.image} alt={car.model} className=" object-cover rounded-t-lg" />
          <h2 className="text-2xl mt-4">{car.model}</h2>
          <p className="text-lg text-gray-600">Price: {car.price}</p>
        </div>
      ))}
    </div>
  );
};

export default FeaturedCars;
